import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import CustomTextInput from "components/partials/custom-text-input/custom-text-input";
import Textarea from "components/partials/textarea/textarea";

const NewBatchForm = () => {
  const { control, errors } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <CustomTextInput
            label="Name"
            labelInTop={true}
            required={true}
            errorsNames={errors.name}
            inputWrapperCustomClass="mb-4 w-full"
            placeholder="Type batch name..."
            {...field}
            onChange={(e) => field.onChange(e.target.value)}
          />
        )}
      />
      <Controller
        control={control}
        name="description"
        render={({ field }) => (
          <Textarea
            {...field}
            required={true}
            label="Description"
            errorsNames={errors.description}
            placeholder="Type description..."
            onChange={field.onChange}
          />
        )}
      />
    </>
  );
};

export default NewBatchForm;
