import React, { useState, useRef, useEffect, memo } from "react";
import uniqid from "uniqid";

import CustomButton from "components/partials/custom-button/custom-button";

import ActionMenuItem from "./action-menu-btn/action-menu-btn";

import Icons from "assets/icons";

const ActionMenu = ({
  buttonName = "",
  iconBefore = <Icons.UploadBatch customClass="mr-2" />,
  options = [],
  onAction,
  isActionBtnDisabled = false,
  isActionMenuDisabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef();
  const actionMenuBtn = useRef();

  const handleClickOutside = (e) => {
    if (!actionMenuBtn.current?.contains(e.target) && !dropdownRef.current?.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative h-[44px] min-w-[200px]">
      <div className="flex h-full justify-end">
        {!!buttonName && (
          <CustomButton
            customClass="action-button font-semibold !px-5"
            onClick={onAction}
            disabled={isActionBtnDisabled || isActionMenuDisabled}
            iconBefore={iconBefore}
            variant="primary"
            size="md"
          >
            {buttonName}
          </CustomButton>
        )}
        {!!options.length && (
          <CustomButton
            ref={actionMenuBtn}
            customClass="ml-1.5"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            variant="secondary"
            size="md"
            disabled={isActionMenuDisabled}
          >
            <Icons.ThreeDots customClass="w-1.5 h-5" />
          </CustomButton>
        )}
      </div>
      {isOpen && (
        <div
          className="dropdown-menu absolute z-[700] mt-1 w-full rounded-lg border border-blue-04 bg-white p-2 text-left shadow-lg"
          ref={dropdownRef}
        >
          {options.map((option) => (
            <ActionMenuItem key={uniqid()} hideDropdownCallback={() => setIsOpen(false)} {...option} />
          ))}
        </div>
      )}
    </div>
  );
};

export default memo(ActionMenu);
