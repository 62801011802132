import { Link as RouterLink } from "react-router-dom";
import CustomButton from "components/partials/custom-button/custom-button";
import { formatDate } from "helpers/dates-formatter";

const commonStyles = "border-r-2 border-white font-semibold";

export const tableSettings = () =>
  [
    {
      id: "meetingName",
      accessorKey: "meetingName",
      header: () => <span>Meeting Name</span>,
      cell: (info) => <span className="text-sm font-semibold">{info.renderValue()}</span>,
      headerClassName: `${commonStyles} rounded-tl-md rounded-bl-md cursor-pointer`,
      className: "text-left pl-4 w-[22%]",
      enableSorting: false,
      sortingFn: "alphanumeric",
    },
    {
      id: "client",
      accessorKey: "client",
      header: () => <span>Partner</span>,
      cell: (info) => <span className="text-sm font-semibold">{info.renderValue()}</span>,
      headerClassName: `${commonStyles}  cursor-pointer`,
      className: "text-left pl-4 w-[17%]",
      enableSorting: false,
      sortingFn: "alphanumeric",
    },
    {
      id: "source",
      accessorKey: "meetingSource",
      header: () => <span>Meeting Source</span>,
      cell: (info) => info.renderValue(),
      headerClassName: `${commonStyles} cursor-context-menu`,
      className: "text-left pl-4 sm:w-[8%] py-4",
      enableSorting: false,
    },
    {
      id: "sourceDetail",
      accessorKey: "meetingSourceDetail",
      header: () => <span>Meeting Source Detail</span>,
      cell: (info) => info.renderValue(),
      headerClassName: `${commonStyles} cursor-context-menu`,
      className: "text-left pl-4 sm:w-[16%]",
      enableSorting: false,
    },
    {
      id: "meetingDate",
      accessorKey: "meetingDate",
      header: () => <span>Meeting Date</span>,
      cell: (info) => formatDate(info.row.original.meetingDate, "MMM D, YYYY h:mm A"),
      headerClassName: `${commonStyles} cursor-pointer`,
      className: "pl-4 sm:w-[12%]",
      enableSorting: true,
    },
    {
      id: "meetingSyncedBy",
      accessorKey: "meetingSyncedBy",
      header: () => <span>Synced By</span>,
      cell: (info) => info.renderValue(),
      headerClassName: `${commonStyles} cursor-pointer`,
      className: "pl-4 sm:w-[12%]",
      enableSorting: false,
    },
    {
      id: "actions",
      header: () => <span className="font-semibold">Actions</span>,
      cell: (info) => (
        <div className="flex justify-center">
          <RouterLink to={`${info.row.original.id}/details`}>
            <CustomButton variant="secondary" size="sm">
              View Meeting Details
            </CustomButton>
          </RouterLink>
        </div>
      ),
      isCellCentered: true,
      isHeaderCentered: true,
      headerClassName: "rounded-tr-md rounded-br-md",
      className: "py-4",
      enableSorting: false,
    },
  ].filter((item) => item);
