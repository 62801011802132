// SummarizeModal.jsx
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import RadioGroup from "components/partials/RadioGroup";
import CustomButton from "components/partials/custom-button/custom-button";
import Heading from "components/partials/heading/heading";
import classNames from "classnames";

const SummarizeModal = ({ onClose, onSubmit, sourceOptions }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const modalRef = useRef(null);

  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (selectedValue) {
      onSubmit(selectedValue);
      onClose();
    }
  };

  const isSubmitDisabled = !selectedValue;

  // Accessibility: Manage focus and handle Escape key
  useEffect(() => {
    const previouslyFocusedElement = document.activeElement;
    if (modalRef.current) {
      modalRef.current.focus();
    }

    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      if (previouslyFocusedElement) {
        previouslyFocusedElement.focus();
      }
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const modalContent = (
    <div
      className="fixed inset-0 z-[701] h-screen max-h-screen overflow-hidden"
      aria-modal="true"
      role="dialog"
      aria-labelledby="summarize-modal-heading"
      tabIndex="-1"
      ref={modalRef}
    >
      <div className="absolute inset-0 !bg-gray-07 opacity-60" onClick={onClose} />
      <div className="absolute left-1/2 top-2/4 -translate-x-1/2 -translate-y-1/2">
        <div className={classNames("flex flex-col justify-between overflow-hidden rounded-md bg-white p-8 mobile:w-[330px] tablet:w-[500px]")}>
          <Heading variant="h1" customClass="font-bold" id="summarize-modal-heading">
            Summarize Meeting
          </Heading>

          <p className="mt-1 text-sm mobile:text-center tablet:text-left">
            Would you like the AI to focus on pulling goals from the conversation or provide an overall general meeting summary?
          </p>
          <form onSubmit={handleFormSubmit} className="flex w-full flex-col">
            <RadioGroup
              label=""
              htmlFor="summary-type-radio-group"
              options={sourceOptions.map((option, index) => ({
                ...option,
                id: `summary-option-${index}`,
                key: index,
                radioValue: option.value,
                isChecked: selectedValue === option.value,
              }))}
              onCheckboxClick={handleRadioChange}
              customClasses="flex-col pt-5"
            />
            <div className="mt-6 flex justify-end space-y-2 mobile:flex-col-reverse tablet:flex-row tablet:space-y-0">
              <CustomButton onClick={onClose} className="mr-2">
                <span className="text-blue-03">Cancel</span>
              </CustomButton>
              <CustomButton variant="primary" disabled={isSubmitDisabled} type="submit">
                Submit
              </CustomButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalContent, document.getElementById("modal-container"));
};

export default SummarizeModal;
