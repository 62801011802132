import React from "react";
import { flexRender } from "@tanstack/react-table";
import uniqid from "uniqid";
import classNames from "classnames";

import { getStylesForTable } from "components/partials/table-component/helpers/get-styles-for-table";
import { getSortIcon } from "components/partials/table-component/helpers/get-sort-icon";
import Loader from "components/partials/loader/loader";

export const Table = ({ table, sortingParams, isLoading, isMultiSort, customHeight, lastRowBold }) => (
  <>
    <table className="w-full table-fixed border-separate border-spacing-0">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={uniqid()}>
            {headerGroup.headers.map((header) => {
              const isColumnSorted = header.column.getSortIndex() >= 0;

              const headerPositionClass = isMultiSort ? "justify-between" : header.column.columnDef.isHeaderCentered && "justify-center";

              return (
                <th
                  key={uniqid()}
                  className={classNames(`${header.column.columnDef.className} text-xs ${header.column.columnDef.headerClassName}`, {
                    "bg-blue-01 text-white": isColumnSorted,
                    "bg-gray-01 text-blue-01": !isColumnSorted,
                  })}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <div className={`flex items-center ${headerPositionClass}`}>
                    <div className="w-100 flex justify-center">
                      <div key={uniqid()} className="pr-1">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                      {getSortIcon(sortingParams, header.column.columnDef.id, header.column.columnDef.enableSorting)}
                    </div>
                    {isMultiSort && isColumnSorted && <div className="pr-4">{header.column.getSortIndex() + 1}</div>}
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
    </table>

    <div className={`mt-2 flex overflow-y-auto text-gray-05 custom-scrollbar ${customHeight}`}>
      <table className="w-full border-separate border-spacing-0">
        <tbody>
          {!isLoading ? (
            table.getRowModel().rows.map((row, rowInd) => (
              <tr className="p-4" key={uniqid()}>
                {row.getVisibleCells().map((cell, cellInd) => {
                  const rowsLength = table.getRowModel().rows.length;
                  const lastCellInd = row.getVisibleCells().length - 1;

                  const styles = getStylesForTable(rowInd, cellInd, rowsLength, lastCellInd);

                  return (
                    <td className={`${styles} border border-gray-02 ${cell.column.columnDef.className}`} key={uniqid()}>
                      <div
                        className={classNames("flex items-center", {
                          "font-semibold": rowsLength - 1 === rowInd && lastRowBold,
                          "justify-center": cell.column.columnDef.isCellCentered,
                        })}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))) :
            <Loader />
          }
        </tbody>
      </table>
    </div>
  </>
);
