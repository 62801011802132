import React from "react";

import CustomRadioInput from "components/partials/custom-radio-input/custom-radio-input";

const RadioGroup = ({ label, htmlFor, options, onCheckboxClick, customClasses = "items-center" }) => {
  return (
    <div className={`flex py-1.5 text-gray-05 ${customClasses}`}>
      <label htmlFor={htmlFor} className="mb-0.5 mr-4 text-lg font-semibold">
        {label}
      </label>
      <div className="flex items-center">
        {options.map(({ label, value, id, key, radioValue, isChecked }) => (
          <CustomRadioInput
            label={label}
            id={id}
            radioValue={radioValue}
            setAction={onCheckboxClick}
            onChange={onCheckboxClick}
            key={key}
            customStylesLabel={"!mb-0"}
            isChecked={isChecked}
            value={value}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;
