import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import RadioGroup from "components/partials/RadioGroup";
import CustomButton from "components/partials/custom-button/custom-button";
import Heading from "components/partials/heading/heading";
import classNames from "classnames";

const RadioModal = ({ onClose, onSelect, sourceOptions }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const modalWrapperRef = useRef(null);
  const modalInnerRef = useRef(null);

  const handleCheckboxClick = (value) => {
    setSelectedValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedValue) {
      onSelect(selectedValue);
      onClose();
    }
  };

  const isSaveBtnDisabled = !selectedValue;

  const modalContent = (
    <div className="fixed inset-0 z-[701] h-screen max-h-screen overflow-hidden" ref={modalWrapperRef}>
      <div className="absolute inset-0 !bg-gray-07 opacity-60" />
      <div className="absolute left-1/2 top-2/4 -translate-x-1/2 -translate-y-1/2">
        <div
          ref={modalInnerRef}
          className={classNames("flex flex-col justify-between overflow-hidden rounded-md bg-white p-8 mobile:w-[330px] tablet:w-[500px]")}
        >
          <Heading variant="h1" customClass="font-bold">
            Select Source
          </Heading>
          <p className="mt-1 text-sm mobile:text-center tablet:text-left">Would you like to select a Gong call or manually upload a VTT file?</p>
          <form onSubmit={handleSubmit} className="flex w-full flex-col">
            <RadioGroup
              label=""
              htmlFor="source-radio-group"
              options={sourceOptions.map((option, index) => ({
                ...option,
                id: `source-option-${index}`,
                key: index,
                radioValue: option.value,
                isChecked: selectedValue === option.value,
              }))}
              onCheckboxClick={handleCheckboxClick}
              customClasses="flex-col pt-5"
            />
            <div className="mt-6 flex justify-end space-y-2 mobile:flex-col-reverse tablet:flex-row tablet:space-y-0">
              <CustomButton
                onClick={() => {
                  onClose();
                }}
              >
                <span className="text-blue-03">Cancel</span>
              </CustomButton>
              <CustomButton variant="primary" disabled={isSaveBtnDisabled} type="submit">
                Submit
              </CustomButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalContent, document.getElementById("modal-container"));
};

export default RadioModal;
