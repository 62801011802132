import React, { createContext, useState, useContext, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import apiRoot from "../api/apiRoot";

export const MeetingDetailContext = createContext();

export const MeetingDetailStore = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [isMeetingLoading, setIsMeetingLoading] = useState(false);
  const [meetingDetails, setMeetingDetails] = useState({});
  const [meetingSummaries, setMeetingSummaries] = useState([]);
  const [summary, setSummary] = useState();
  const [format, setFormat] = useState("markdown");

  const resetMeetingDetails = () => {
    setMeetingDetails({});
    setMeetingSummaries([]);
  };

  /**
   * Determines whether the provided data is in HTML or Markdown format.
   *
   * @param {string} data - The summary content to analyze.
   * @returns {string} - Returns 'html' if HTML tags are detected, otherwise 'markdown'.
   */
  const determineFormat = (data) => {
    // Trim the data to remove leading/trailing whitespace
    const trimmedData = data.trim();

    // Enhanced regular expression to detect common HTML tags
    const htmlTagPattern = /<\/?(div|span|p|h1|h2|h3|ul|ol|li|strong|em|a|img|table|tr|td|th)[\s\S]*?>/i;

    // Regular expressions to detect Markdown syntax
    const markdownPatterns = [
      /^#{1,6}\s/, // Headers: # Header
      /^\*\s/, // Bullet points: * Item
      /^-\s/, // Bullet points: - Item
      /^\d+\.\s/, // Numbered lists: 1. Item
      /\*\*[^*]+\*\*/, // Bold text: **bold**
      /\*[^*]+\*/, // Italic text: *italic*
      /`[^`]+`/, // Inline code: `code`
      /!\[.*\]\(.*\)/, // Images: ![alt](url)
      /\[.*\]\(.*\)/, // Links: [text](url)
      /^>\s/, // Blockquotes: > Quote
      /^---$/, // Horizontal rule
      /~~[^~]+~~/, // Strikethrough: ~~text~~
      /\n{2,}/, // Paragraph breaks
    ];

    // Check for HTML tags
    if (htmlTagPattern.test(trimmedData)) {
      return "html";
    }

    // Check for Markdown patterns
    const isMarkdown = markdownPatterns.some((pattern) => pattern.test(trimmedData));
    if (isMarkdown) {
      return "markdown";
    }

    // Default to Markdown if no clear indicators are found
    return "markdown";
  };

  const fetchMeetingDetails = async (meetingId) => {
    setIsMeetingLoading(true);
    apiRoot
      .get(`/meetings/${meetingId}/details`)
      .then((res) => {
        setMeetingDetails(res.data.meetingInfo);
        /* 
          Need to see over time if it makes sense to move the logic to get the meeting summary at the point in which 
          the users wants to view the summary rather than returning all the summaries here. As of now this feels okay but 
          if we have many summaries with different types it might make sense to move this to be a separate API call
        */
        setMeetingSummaries(res.data.summaries);
      })
      .catch((err) => console.error("Meeting details request failed", err))
      .finally(() => setIsMeetingLoading(false));
  };

  const summarizeMeeting = async (meetingId, summaryType) => {
    setIsMeetingLoading(true);

    apiRoot
      .get(`/meetings/${meetingId}/summarize?userId=${user.id}&summaryType=${summaryType}`)
      .then((res) => {
        setMeetingDetails(res.data.meetingInfo);
        setMeetingSummaries(res.data.summaries);
        setSummary(res.data.summaries[0].transcriptSummary);
      })
      .catch((err) => console.error("Meeting details request failed", err))
      .finally(() => setIsMeetingLoading(false));
  };

  const value = {
    fetchMeetingDetails,
    meetingDetails,
    meetingSummaries,
    isMeetingLoading,
    resetMeetingDetails,
    summarizeMeeting,
    summary,
    setSummary,
    format,
    determineFormat,
    setFormat,
  };

  return <MeetingDetailContext.Provider value={value}>{children}</MeetingDetailContext.Provider>;
};
