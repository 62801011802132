import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "components/PrivateRoutes";
import appRoles from "config/appRoles";
import Meetings from "./transcript-summarization/meetings";
import NewSummaryGong from "./transcript-summarization/new-summary/gong/new-summary-gong";
import NewSummaryManual from "./transcript-summarization/new-summary/manual/new-summary-manual";
import MeetingDetails from "./transcript-summarization/summary-details/meeting-details";

const IntelligenceCenterRoutes = () => (
  <Routes>
    <Route element={<PrivateRoutes allowedAppRoles={appRoles.IsAiTranscriptSummarizationReader} />}>
      <Route exact path="meetings" element={<Meetings />} />
      <Route exact path="meetings/new/gong" element={<NewSummaryGong />} />
      <Route exact path="meetings/new/manual" element={<NewSummaryManual />} />
      <Route exact path="meetings/:meetingId/*" element={<MeetingDetails />} />
    </Route>
  </Routes>
);

export default IntelligenceCenterRoutes;
