import React, { useContext } from "react";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import Heading from "components/partials/heading/heading";
import CustomButton from "components/partials/custom-button/custom-button";
import TableComponent from "components/partials/table-component/table-component";
import EmptySpaceContent from "components/partials/empty-space-content/empty-space-content";
import NewBatchForm from "pages/list-plus/new-batch/new-batch-form/new-batch-form";
import Loader from "components/partials/loader/loader";

import { useBatchThunk } from "state/batch/batch.thunk";

import { ListPlusContext } from "context/ListPlusContext";

import { listUploadsTableSettings } from "pages/list-plus/list-uploads/list-uploads-table-settings";

import { renderFormModal } from "helpers/render-modal";

import Icons from "assets/icons";

const ListUploads = () => {
  const { clientid } = useParams();

  const { createBatch } = useBatchThunk();

  const {
    isListUploadsLoading,
    batchStatuses,
    listUploadsData,
    pageSize,
    totalRecords,
    setAmount,
    pageIndex,
    setPageIndex,
    sortingParams,
    setSortingParams,
  } = useContext(ListPlusContext);

  const navigate = useNavigate();

  const handleCreateBatch = () => {
    renderFormModal({
      headerText: "New List Batch",
      onSubmitCallback: (formData) => createBatch(formData, navigate, clientid),
      submitButtonLabel: "Start new list batch",
      submitButtonIcon: (
        <Icons.UploadBatch
          aria-label="Start new list batch"
          height="100%"
          width="25"
        />
      ),
      children: <NewBatchForm />,
    });
  };
  return (
    <>
      <ToastContainer style={{ width: "100%", fontSize: "16px" }} progressStyle={{ backgroundColor: "#b6de3a" }} />
      <div className="mb-9 flex shrink grow basis-0 flex-col gap-2">
        <div className="mb-5 flex items-center justify-between">
          <Heading variant="h1" customClass="font-bold">
            List Uploads
          </Heading>
          <CustomButton
            variant="primary"
            size="md"
            iconBefore={<Icons.UploadBatch aria-label="Upload batch" height="100%" width="25" />}
            onClick={handleCreateBatch}
          >
            Upload new list batch
          </CustomButton>
        </div>
        {!isListUploadsLoading ? (
          !!listUploadsData.length ? (
            <TableComponent
              columns={listUploadsTableSettings(batchStatuses)}
              data={listUploadsData}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={(page) => {
                setPageIndex(page);
              }}
              setAmount={setAmount}
              sortingParams={sortingParams}
              setSortingParams={setSortingParams}
              totalRecords={totalRecords}
              customHeight={listUploadsData.length === pageSize && "shrink grow basis-0"}
            />
          ) : (
            <EmptySpaceContent customClass="!mt-14" image={<Icons.EmptyImage customClass="w-[210px] h-[210px]" />} text="Sorry, no results found." />
          )
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default ListUploads;
