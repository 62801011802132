import appRoles from "config/appRoles";

export const SIDEBAR_ITEMS = [
  {
    label: "File split",
    route: "file-split",
    allowedAppRoles: appRoles.IsFileSplitReader,
  },
  {
    label: "Move files",
    route: "move-files",
    allowedAppRoles: appRoles.IsFileMoverReader,
  },
  {
    label: "Data profiling",
    route: "data-profiling",
    allowedAppRoles: appRoles.IsFileSplitReader,
  },
  {
    label: "Config",
    route: "config",
    allowedAppRoles: appRoles.IsConfigReader,
  },
  {
    label: "File masks",
    route: "file-masks",
    allowedAppRoles: appRoles.IsFileSetupReader,
  },
  {
    label: "File mappings",
    route: "file-mappings",
    allowedAppRoles: appRoles.IsFileSetupReader,
  },
  {
    label: "List plus",
    route: "list-plus",
    allowedAppRoles: appRoles.IsListPlusReader,
    subnav: [
      {
        label: "List Uploads",
        route: "list-plus/list-uploads",
      },
    ],
  },
];
