import React from "react";

// Import all the icons in this file
//main pages
import { ReactComponent as Profile } from "assets/icons/profile.svg";
import { ReactComponent as Clients } from "assets/icons/clients.svg";
//notifications
import { ReactComponent as Configuring } from "assets/icons/configuring.svg";
import { ReactComponent as Processing } from "assets/icons/processing.svg";
import { ReactComponent as ReadyForQA } from "assets/icons/upload-cloud.svg";
import { ReactComponent as Success } from "assets/icons/success.svg";
import { ReactComponent as Warning } from "assets/icons/warning.svg";
//actions
import { ReactComponent as SplitFile } from "assets/icons/splitfile_icon.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as Undo } from "assets/icons/undo.svg";
import { ReactComponent as Copy } from "assets/icons/copy.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as UploadBatch } from "assets/icons/upload-batch.svg";
import { ReactComponent as ProcessBatch } from "assets/icons/process-batch.svg";
import { ReactComponent as PublishToClient } from "assets/icons/publish-to-client.svg";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ReactComponent as Info } from "assets/icons/info.svg";
//labels
import { ReactComponent as BookmarkedIcon } from "assets/icons/bookmarked.svg";
import { ReactComponent as UnbookmarkedIcon } from "assets/icons/unbookmarked.svg";
import { ReactComponent as ThreeDots } from "assets/icons/three-dots-menu.svg";
import { ReactComponent as File } from "assets/icons/file.svg";
//images
import { ReactComponent as AtomLogo } from "assets/images/atom-logo.svg";
import { ReactComponent as MarketviewLogo } from "assets/images/marketview-logo.svg";
import { ReactComponent as EmptyImage } from "assets/icons/no-all-empty.svg";
//table icons
import { ReactComponent as DefaultSort } from "assets/icons/default-sort.svg";
import { ReactComponent as Sort } from "assets/icons/sort.svg";
//other
import { ReactComponent as Arrow } from "assets/icons/angle-right.svg";
import { ReactComponent as DoubleArrow } from "assets/icons/double-angle-right.svg";
import { ReactComponent as Intelligence } from "assets/icons/ai-transcript-summary.svg";
import { ReactComponent as Summary } from "assets/icons/summary.svg";
import { ReactComponent as Settings } from "assets/icons/settings.svg";
import { ReactComponent as RequiredIndicator } from "assets/icons/required-indicator.svg";
import { ReactComponent as Spinner } from "assets/icons/loading.svg";

const icons = {
  //main pages
  Clients,
  Profile,
  //notifications
  Configuring,
  ReadyForQA,
  Processing,
  Success,
  Warning,
  //actions
  SplitFile,
  Delete,
  Undo,
  Copy,
  Edit,
  UploadBatch,
  ProcessBatch,
  PublishToClient,
  Plus,
  Info,
  //labels
  BookmarkedIcon,
  UnbookmarkedIcon,
  ThreeDots,
  File,
  //images
  AtomLogo,
  MarketviewLogo,
  EmptyImage,
  //table icons
  DefaultSort,
  Sort,
  //other
  Arrow,
  DoubleArrow,
  Intelligence,
  Summary,
  RequiredIndicator,
  Settings,
  Spinner,
};

// Wrap our icons in Boxes so we can use our color/space classes.
const Icons = Object.keys(icons).reduce((result, icon) => {
  result[icon] = ({ customClass = "", onClick, ...rest }) => {
    const Icon = icons[icon];

    return <Icon className={`block ${customClass}`} onClick={onClick} {...rest} />;
  };

  return result;
}, {});

export default Icons;
