import classNames from "classnames";

const defaultItemCss = "flex items-center leading-5 no-underline gap-2";

//main items
export const getNavItemStyles = (isActive) => {
  const basicCss = `${defaultItemCss} h-12 text-lg`;

  return classNames(basicCss, {
    "text-gray-03": !isActive,
    "border-b-4 border-solid border-b-yellow-01 font-semibold text-blue-03": isActive,
  });
};
