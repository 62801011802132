import React from "react";
import SearchFile from "../components/partials/SearchFile";

const FileSearchNoType = ({
  setSearchResults,
  searchLabel,
  setSearchTerm,
  searchTerm,
  additionalCssClass,
  allowAppRoles,
}) => {
  return (
    <div className={`flex items-center gap-8 ${additionalCssClass}`}>
      <SearchFile
        setSearchResults={setSearchResults}
        label={searchLabel}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        allowAppRoles={allowAppRoles}
      />
    </div>
  );
};

export default FileSearchNoType;
