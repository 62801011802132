import React, { createContext, useState, useContext, useEffect } from "react";
import { readebleFileSize } from "components/partials/file-uploader/file-uploader.helper";
import { AuthContext } from "./AuthContext";
import apiRoot from "../api/apiRoot";
import { toast } from "react-toastify";
import { getSortingParamsQuery } from "helpers/get-query";

export const MeetingContext = createContext();

export const MeetingStore = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [transcriptUploads, setTranscriptUploads] = useState([]);
  const [linkedClient, setLinkedClient] = useState("");
  const [isMeetingsLoading, setIsMeetingsLoading] = useState(false);
  const [recentMeetings, setRecentMeetings] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [{ pageSize, totalRecords }, setAmount] = useState({
    pageSize: 10,
    totalRecords: 0,
  });

  const [sortingParams, setSortingParams] = useState([{ id: "meetingDate", desc: true }]);
  const [searchTerm, setSearchTerm] = useState("");
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    if (user.id && !isMeetingsLoading) {
      setIsMeetingsLoading(true);
      fetchMeetings(false, searchTerm, clientId);
    }
    // eslint-disable-next-line
  }, [user, pageIndex, pageSize, sortingParams, searchTerm, clientId]);

  const fetchMeetings = (isGongOnly, searchTerm, clientId) => {
    setIsMeetingsLoading(true);
    const sortingQuery = getSortingParamsQuery(sortingParams);
    // This is kinda weird but the way the BE is setup if we don't pass the userId it won't include it in the meeting_synced_by. Feels hacky but it works
    // This will probably break on refresh of the page so I should probably revisit
    const userId = isGongOnly ? `userId=&` : `userId=${user.id}&`;
    const searchParam = searchTerm ? `&search=${searchTerm}` : "";
    const filterParam = clientId ? `&clientId=${clientId}` : "";

    console.log(`/meetings?${userId}page=${pageIndex}&size=${pageSize}${sortingQuery}${searchParam}${filterParam}`);

    apiRoot
      .get(`/meetings?${userId}page=${pageIndex}&size=${pageSize}${sortingQuery}${searchParam}${filterParam}`)
      .then((res) => {
        setRecentMeetings(res.data.content);
        setAmount({
          pageSize,
          totalRecords: res.data.totalElements,
        });
      })
      .catch((err) => console.error("Meetings request failed", err))
      .finally(() => setIsMeetingsLoading(false));
  };

  const onTranscriptFileRemove = () => {
    setTranscriptUploads([]);
  };

  const onTranscriptFileUpload = (files) => {
    if (files?.length && files.length >= 1) {
      setTranscriptUploads([
        {
          FileName: files[0].name,
          LinkedClient: linkedClient,
          FileSize: readebleFileSize(files[0].size),
          File: files[0],
        },
      ]);
    }
  };

  const updateLinkedClient = (client) => {
    if (transcriptUploads[0]) {
      setTranscriptUploads([{ ...transcriptUploads[0], LinkedClient: client }]);
    }
    setLinkedClient(client);
  };

  const handleTranscriptFileUpload = async (summaryType) => {
    const toastId = toast.loading("Uploading, Saving, and Summarizing Transcript");
    setIsMeetingsLoading(true);
    let formData = new FormData();

    formData.append(`transcriptFiles`, transcriptUploads[0].File, `${transcriptUploads[0].FileName}`);

    return apiRoot
      .post(`/meetings/manual/summarize?userId=${user.id}&clientId=${linkedClient?.id}&summaryType=${summaryType}`, formData)
      .then((res) => {
        if (!res?.status) {
          toast.update(toastId, {
            render: (
              <div>
                FAILED - Unable to summarize transcript. Retry or{" "}
                <a href="https://intake.marketviewedu.com" target="_blank" rel="noopener noreferrer" className="text-blue-400 underline">
                  reach out to support
                </a>{" "}
                for assistance!
              </div>
            ),
            type: "error",
            isLoading: false,
            autoClose: false,
            closeButton: true,
            style: { borderBottom: "4px solid #fc0303" },
          });

          setIsMeetingsLoading(false);
          return;
        }

        setLinkedClient("");
        setTranscriptUploads([]);
        setIsMeetingsLoading(false);

        return res.data; // Return the response data
      })
      .catch((err) => {
        console.error("Meetings request failed", err);
        setIsMeetingsLoading(false);
      });
  };

  const value = {
    transcriptUploads,
    handleTranscriptFileUpload,
    onTranscriptFileUpload,
    onTranscriptFileRemove,
    setLinkedClient,
    updateLinkedClient,
    recentMeetings,
    totalRecords,
    pageIndex,
    setPageIndex,
    pageSize,
    setAmount,
    sortingParams,
    setSortingParams,
    isMeetingsLoading,
    fetchMeetings,
    setRecentMeetings,
    searchTerm,
    setSearchTerm,
    clientId,
    setClientId,
  };

  return <MeetingContext.Provider value={value}>{children}</MeetingContext.Provider>;
};
