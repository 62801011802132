import React, { useState, useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import PrivateNavLink from "components/PrivateNavLink";
import IntelligenceCenterRoutes from "./intelligence-center.routes";
import { SIDEBAR_ITEMS } from "./intelligence-center.constants";
import uniqid from "uniqid";
import { getNavItemStyles } from "pages/intelligence-center/helpers/get-styles";
import { MeetingStore } from "context/MeetingContext";
import { MeetingDetailStore } from "context/MeetingDetailContext";
import CustomButton from "components/partials/custom-button/custom-button";
import Icons from "assets/icons";
import { FeedbackContext } from "context/FeedbackContext";
import FeedbackForm from "components/partials/feedback-form/feedback-form";

const IntelligenceCenter = () => {
  const [isFeedbackFormVisible, setIsFeedbackFormVisible] = useState(false);
  const { submitFeedback } = useContext(FeedbackContext);
  const location = useLocation();

  const renderLinks = () => {
    return (
      <div className="flex min-w-[150px] flex-col gap-5 px-3 pt-1">
        {SIDEBAR_ITEMS.map((app) => {
          return (
            <div key={uniqid()} className="flex items-center gap-2">
              {app?.icon ? app.icon : <></>}
              <PrivateNavLink
                to={app.route}
                className={({ isActive }) => getNavItemStyles(isActive)}
                key={app.value}
                allowedAppRoles={app.allowedAppRoles}
              >
                {app.label}
              </PrivateNavLink>
            </div>
          );
        })}
      </div>
    );
  };

  const handleFeedbackSubmit = (feedbackType, feedbackText, onComplete) => {
    submitFeedback(feedbackType, feedbackText, `{location: ${location.pathname} ,button: Generic intelligence center feedback}`).finally(onComplete);
  };
  return (
    <MeetingStore>
      <MeetingDetailStore>
        <div className="flex flex-col border-b border-solid border-gray-02 pb-6 pl-10 pt-4">
          <div className="mt-1 text-3xl font-black leading-7 text-blue-03">Intelligence Center</div>
        </div>
        <div className="flex w-full flex-grow">
          <div className="flex flex-col justify-between px-2">
            <div className="flex min-w-[255px] flex-col">{renderLinks()}</div>
            <div className="flex flex-col">
              {isFeedbackFormVisible && (
                <FeedbackForm onClose={() => setIsFeedbackFormVisible(false)} onSubmit={handleFeedbackSubmit} verticalPosition="above" />
              )}
              <CustomButton
                variant="primary"
                size="md"
                iconBefore={<Icons.Info aria-label="Feedback" height="100%" width="20" />}
                onClick={() => setIsFeedbackFormVisible(true)}
              >
                Thoughts?
              </CustomButton>
            </div>
          </div>

          <div className="flex min-h-[calc(100vh-157px)] w-full flex-col border-l border-gray-02 p-3">
            <IntelligenceCenterRoutes />
            <Outlet />
          </div>
        </div>
      </MeetingDetailStore>
    </MeetingStore>
  );
};

export default IntelligenceCenter;
