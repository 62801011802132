import React, { createContext, useState, useEffect, useContext } from "react";
import fileSplitApi from "../api/fileSplitApi";
import { v4 as uuidv4 } from "uuid";
import { ClientContext } from "./ClientContext";

export const DataprofilingContext = createContext();

export const DataprofilingStore = ({ children }) => {
  const [clients, setClients] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState([]);
  const [queuedFiles, setQueuedFiles] = useState([]);
  const [profilingFile, setProfilingFile] = useState([]);
  const [completedFiles, setCompletedFiles] = useState([]);
  const [isProfiling, setIsProfiling] = useState(false);
  const { currentClientId } = useContext(ClientContext);

  useEffect(() => {
    setCompletedFiles([]);
    setProfilingFile([]);
    setQueuedFiles([]);
  }, [currentClientId]);

  function profileFile(file) {
    fileSplitApi
      .post("/http-dataprofiling", {
        override_env: "dev",
        event_subject: file.filePath.substring(1),
      })
      .then((response) => {
        const newCompletedFiles = [
          ...completedFiles,
          { ...file, status: "Success", fileName: response.data },
        ];
        setIsProfiling(false);
        setCompletedFiles(newCompletedFiles);
        setProfilingFile([]);
      })
      .catch((response) => {
        const newCompletedFiles = [
          ...completedFiles,
          { ...file, status: "Failed", fileName: [] },
        ];
        setIsProfiling(false);
        setCompletedFiles(newCompletedFiles);
        setProfilingFile([]);
      });
  }

  useEffect(() => {
    if (profilingFile.length === 0) {
      pollQueuedFiles();
    }
    // eslint-disable-next-line
  }, [queuedFiles, completedFiles, profilingFile]);

  function pollQueuedFiles() {
    if (!isProfiling && queuedFiles.length > 0) {
      const newQueuedFiles = [...queuedFiles];
      const fileToSplit = newQueuedFiles.shift();
      setQueuedFiles(newQueuedFiles);
      setIsProfiling(true);
      setProfilingFile([
        { ...fileToSplit, status: "Profiling", fileName: [] },
      ]);
      profileFile(fileToSplit);
    }
  }

  const addFile = (file) => {
    setQueuedFiles([
      ...queuedFiles,
      { ...file, status: "Queued", id: uuidv4(), fileName: [] },
    ]);
  };

  const addFileArray = (files) => {
    const newFiles = [...queuedFiles];
    files.map((file) => {
      newFiles.push({
        ...file,
        status: "Queued",
        id: uuidv4(),
        fileName: [],
      });
    });
    setQueuedFiles(newFiles);
  };

  const value = {
    clients: clients,
    setClients: setClients,
    queuedFiles: queuedFiles,
    setQueuedFiles: setQueuedFiles,
    selectedSchool: selectedSchool,
    setSelectedSchool: setSelectedSchool,
    addFile: addFile,
    addFileArray: addFileArray,
    completedFiles: completedFiles,
    profilingFile: profilingFile,
  };
  return (
    <DataprofilingContext.Provider value={value}>
      {children}
    </DataprofilingContext.Provider>
  );
};