import appRoles from "config/appRoles";
import Icons from "assets/icons";

export const SIDEBAR_ITEMS = [
  {
    label: "Meetings",
    route: "meetings",
    allowedAppRoles: appRoles.IsAiTranscriptSummarizationReader,
    icon: <Icons.Summary width="20px" />,
  },
];
