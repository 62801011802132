import React, { createContext, useContext, useState } from "react";
import { AuthContext } from "./AuthContext";
import apiRoot from "../api/apiRoot";

export const FeedbackContext = createContext();

export const FeedbackStore = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [isSavingFeedback, setIsSavingFeedback] = useState(false);

  const submitFeedback = async (feedbackType, message, metadata) => {
    setIsSavingFeedback(true);
    const feedbackData = {
      userId: user.id,
      feedbackType: feedbackType.toUpperCase(),
      message: message,
      metadata: metadata,
    };
  
    return apiRoot
      .post(`/feedback`, feedbackData)
      .then((res) => {
        // Handle success if needed
      })
      .catch((err) => console.error("Failed to save feedback", err))
      .finally(() => setIsSavingFeedback(false));
  };

  const value = {
    submitFeedback,
    isSavingFeedback,
    setIsSavingFeedback,
  };

  return <FeedbackContext.Provider value={value}>{children}</FeedbackContext.Provider>;
};
