import React, { useState, useContext, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Icons from "assets/icons";
import CustomButton from "components/partials/custom-button/custom-button";
import Heading from "components/partials/heading/heading";
import ReactMarkdown from "react-markdown";
import { MeetingDetailContext } from "context/MeetingDetailContext";
import FeedbackForm from "components/partials/feedback-form/feedback-form";
import { FeedbackContext } from "context/FeedbackContext";

const MeetingSummary = ({ summary, activeSummaryId }) => {
  const [isSummaryCopied, setIsSummaryCopied] = useState(false);
  const [isFeedbackFormVisible, setIsFeedbackFormVisible] = useState(false);
  const { format, setFormat, determineFormat } = useContext(MeetingDetailContext);
  const { submitFeedback } = useContext(FeedbackContext);
  const { meetingId } = useParams();
  const location = useLocation();

  useEffect(() => {
    const detectedFormat = determineFormat(summary);
    setFormat(detectedFormat);
  }, [summary]);

  const handleCopyClick = (data, formatType, setIsCopied) => {
    let textToCopy = "";

    if (formatType === "html") {
      // Create a temporary DOM element to strip HTML tags
      const tempElement = document.createElement("div");
      tempElement.innerHTML = data;

      // Get only the raw text content (without HTML tags)
      textToCopy = tempElement.textContent || tempElement.innerText || "";

      // Replace multiple spaces and tabs with a single space, preserve newlines
      textToCopy = textToCopy
        .replace(/[ \t]+/g, " ") // Collapse spaces and tabs into a single space
        .replace(/\r\n/g, "\n") // Normalize line endings
        .replace(/\n\s*\n/g, "\n\n") // Ensure newlines are preserved, remove extra blank lines
        .trim(); // Remove leading/trailing spaces
    } else if (formatType === "markdown") {
      // For Markdown, copy the raw Markdown text
      textToCopy = data.trim();
    }

    // Copy the text to the clipboard
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        // Set copied state to true for 2 seconds
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        // Optionally, you can set an error state here to notify the user
      });
  };

  const handleFeedbackSubmit = (feedbackType, feedbackText, onComplete) => {
    submitFeedback(
      feedbackType,
      feedbackText,
      `{location: ${location.pathname}, meeting_id:${meetingId}, meeting_summary_id:${activeSummaryId}}`
    ).finally(onComplete);
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <Heading variant="h2" customClass="font-semibold">
          Summary
        </Heading>
        <div className="flex gap-2">
          <CustomButton
            variant="secondary"
            size="sm"
            onClick={() => handleCopyClick(summary, format, setIsSummaryCopied)}
            iconBefore={
              isSummaryCopied ? (
                <Icons.Success aria-label="Copy successful icon" className="not-sr-only h-4 w-4 text-blue-03" />
              ) : (
                <Icons.Copy aria-label="Copy icon" className="not-sr-only h-4 w-4 text-blue-03" />
              )
            }
          >
            {isSummaryCopied ? "Copied!" : "Copy text"}
          </CustomButton>
          <CustomButton
            variant="secondary"
            size="sm"
            onClick={() => setIsFeedbackFormVisible(true)}
            iconBefore={<Icons.Info aria-label="Feedback" height="100%" width="20" />}
          >
            Feedback?
          </CustomButton>
          {isFeedbackFormVisible && <FeedbackForm onClose={() => setIsFeedbackFormVisible(false)} onSubmit={handleFeedbackSubmit} />}
        </div>
      </div>
      <div className="mt-2.5 min-h-[600px] basis-0 overflow-y-auto rounded-md border border-gray-02 p-4 custom-scrollbar">
        {format === "html" ? (
          <div className="pb-2 text-lg" dangerouslySetInnerHTML={{ __html: summary }} />
        ) : (
          <ReactMarkdown className="pb-2 text-lg">{summary}</ReactMarkdown>
        )}
      </div>
    </div>
  );
};

export default MeetingSummary;
