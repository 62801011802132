import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MeetingSummary from "./meeting-summary";
import MeetingInfo from "./meeting-info";
import MeetingSummaries from "./meeting-summaries";
import { MeetingDetailContext } from "context/MeetingDetailContext";
import Loader from "components/partials/loader/loader";
import CustomButton from "components/partials/custom-button/custom-button";
import Icons from "assets/icons";
import { MeetingContext } from "context/MeetingContext";

const MeetingDetails = () => {
  const { meetingId } = useParams();
  const navigate = useNavigate();
  const { isMeetingLoading, meetingDetails, meetingSummaries, fetchMeetingDetails, summary, setSummary, determineFormat } =
    useContext(MeetingDetailContext);

  const { setRecentMeetings } = useContext(MeetingContext);

  const [activeIndex, setActiveIndex] = useState(null);
  const [activeMeetingId, setActiveMeetingSummaryId] = useState(null);

  useEffect(() => {
    if (meetingId && !isMeetingLoading) {
      fetchMeetingDetails(meetingId);
      setSummary(null);
    }
  }, [meetingId]);

  const handleViewSummaryClick = (summary, index, id) => {
    determineFormat(summary);
    setSummary(summary);
    setActiveIndex(index);
    setActiveMeetingSummaryId(id)
  };

  const handleBackClick = () => {
    setRecentMeetings([]);
    navigate("/intelligence/meetings");
  };

  return (
    <div>
      <CustomButton
        variant="secondaryWithoutBorder"
        size="smSquare"
        iconBefore={<Icons.Arrow customClass="rotate-180" aria-label="Back" height="100%" width="14" />}
        onClick={() => handleBackClick()}
      >
        {isMeetingLoading ? "Back (You can leave while its summarizing)" : "Back"}
      </CustomButton>
      <div className="grid grid-cols-[2fr_3fr] gap-5 p-3">
        <div className="flex flex-col gap-10">
          {!isMeetingLoading ? (
            <>
              <MeetingInfo meetingInfo={meetingDetails} />
              <MeetingSummaries summaries={meetingSummaries} handleViewSummaryClick={handleViewSummaryClick} activeIndex={activeIndex}/>
            </>
          ) : (
            <Loader />
          )}
        </div>
        {summary && <MeetingSummary summary={summary}  activeSummaryId={activeMeetingId}/>}
      </div>
    </div>
  );
};

export default MeetingDetails;
