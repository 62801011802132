import React, { useState, useContext, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { ToastContainer } from "react-toastify";
import debounce from "lodash.debounce";
import Heading from "components/partials/heading/heading";
import FileUploader from "components/partials/file-uploader/file-uploader";
import Table from "components/Table";
import CustomButton from "components/partials/custom-button/custom-button";
import { useNavigate } from "react-router-dom";
import apiRoot from "api/apiRoot";
import SummarizeModal from "../summarize-modal";
import { MeetingContext } from "context/MeetingContext";
import { AuthContext } from "context/AuthContext";
import appRoles from "config/appRoles";
import { MeetingDetailContext } from "context/MeetingDetailContext";

const NewSummaryManual = ({ isClientLevel = false }) => {
  const [inputValue, setValue] = useState("");

  const { handleTranscriptFileUpload, transcriptUploads, onTranscriptFileRemove, onTranscriptFileUpload, updateLinkedClient, isSummarizing } =
    useContext(MeetingContext);

  const { setSummary } = useContext(MeetingDetailContext);
  const { isAuthorized } = useContext(AuthContext);

  const _loadOptions = (queryTerm, callback) => {
    apiRoot.get(`/client/search?searchTerm=${queryTerm}`).then((res) => callback(res.data));
  };

  const [isSummarizeModalOpen, setIsSummarizeModalOpen] = useState(false);

  const sourceOptions = [
    { label: "General", value: "GENERAL" },
    { label: "Goals", value: "GOALS" },
  ];

  const navigate = useNavigate();

  const handleSummarizeClick = () => {
    setIsSummarizeModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsSummarizeModalOpen(false);
  };

  const handleSummarizeSubmit = (selectedValue) => {
    setSummary(null);
    handleTranscriptFileUpload(selectedValue)
      .then((data) => {
        navigate(`/intelligence/meetings/${data.meetingInfo.id}/details`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadOptions = debounce(_loadOptions, 500);

  const handleChange = (value) => {
    setValue(value);
    updateLinkedClient(value);
  };

  function renderTranscriptUpload() {
    const headers = [
      {
        header: "FILE NAME",
        dataKey: "FileName",
        additionalCssClass: "px-14 text-left",
      },
      {
        header: "LINKED CLIENT",
        dataKey: "LinkedClient",
        nestedKey: "institutionName",
        additionalCssClass: "px-14 text-left",
      },
      {
        header: "FILE SIZE",
        dataKey: "FileSize",
        additionalCssClass: "px-4 text-center",
      },
      {
        header: "REMOVE FILE",
        dataKey: "button",
        additionalCssClass: "px-4 text-center",
      },
    ];
    return (
      <div className="flex max-w-[1000px] flex-col gap-4">
        <p className="pb-1 text-base font-semibold leading-5 text-blue-03">Current Transcript File</p>
        <Table
          headers={headers}
          data={transcriptUploads}
          buttonFunction={onTranscriptFileRemove}
          buttonLabel={"Remove"}
          buttonLabelClassName={"text-blue-03 font-semibold"}
          buttonClassName="mx-auto my-0 flex cursor-pointer items-center gap-1 rounded-md border border-solid border-blue-01 bg-white px-3 py-2"
        />
        <div className="flex flex-grow flex-col gap-2">
          <CustomButton
            variant="primary"
            customClass="bg-yellow-01"
            onClick={handleSummarizeClick}
            disabled={!isAuthorized(appRoles.IsAiTranscriptSummarizationWriter) || isSummarizing}
          >
            Summarize Transcript File
          </CustomButton>
          {!isAuthorized(appRoles.IsAiTranscriptSummarizationWriter) ? (
            <p className="text-sm text-red-600">Not Authorized to Summarize Transcript</p>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  function renderTranscriptAssistantParagraph() {
    return (
      <div>
        <Heading variant="h1" customClass="font-bold" id="summarize-modal-heading">
          Upload Transcript for Summary
        </Heading>

        <p className="whitespace-pre-wrap">
          <br></br>If this was a client call (current or prospective) <strong>please</strong> link them using the search bar
          <strong> below</strong> then upload your transcript file. Otherwise feel free to ignore the institution link<br></br>
          <br></br>Please only upload transcripts in the <strong>VTT</strong> format. This is the standard format downloaded from teams, zoom, etc.
          Your transcript will be stored for future use. The transcript will be parsed, digested, and understood by an AI, afterwards a summarized
          response will be returned to you. Depending on the size of the transcript
          <strong> this could take a while. </strong>
        </p>
      </div>
    );
  }

  function renderTranscriptInstitutionLink() {
    return (
      <div>
        {!isClientLevel ? (
          <>
            <p className="pb-3 text-base font-semibold leading-5 text-blue-03">Link Transcript to Institution</p>
            <div className="flex flex-grow gap-2">
              <div className="w-[580px]">
                <div className="pr-3">
                  <AsyncSelect
                    placeholder="Search for Institution"
                    value={inputValue}
                    getOptionLabel={(e) => `${e.institutionName} - ${e.state} - ${e.schoolKey} - ${e.blobContainer}`}
                    getOptionValue={(e) => e.id}
                    loadOptions={loadOptions}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {inputValue ? (
                <CustomButton
                  variant="primary"
                  customClass="bg-yellow-01"
                  onClick={() => {
                    setValue("");
                    updateLinkedClient("");
                  }}
                >
                  Reset Value
                </CustomButton>
              ) : (
                <> </>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }

  return (
    <>
      <ToastContainer style={{ width: "500px", fontSize: "16px" }} autoClose={true} />
      <div className="flex w-[75%] flex-grow-0 gap-5">
        <div className="flex flex-col gap-4 p-2">
          {renderTranscriptAssistantParagraph()}
          {renderTranscriptInstitutionLink()}
          <div>
            {transcriptUploads?.length > 0 ? (
              renderTranscriptUpload()
            ) : (
              <FileUploader
                label="Upload File"
                multiple={false}
                onChange={onTranscriptFileUpload}
                acceptableFiles={[".docx", ".vtt"]}
                addRadioGroup={false}
                helperText="File uploaded must be either .docx or .vtt and less than 50mb"
              />
            )}
          </div>
        </div>
        {isSummarizeModalOpen && <SummarizeModal onClose={handleCloseModal} onSubmit={handleSummarizeSubmit} sourceOptions={sourceOptions} />}
      </div>
    </>
  );
};

export default NewSummaryManual;
