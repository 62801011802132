import React, { useState, useContext } from "react";
import Textarea from "components/partials/textarea/textarea"; 
import CustomButton from "../custom-button/custom-button";
import { FeedbackContext } from "context/FeedbackContext";

const FeedbackForm = ({ onClose, onSubmit, verticalPosition = "below", horizontalPosition = "right" }) => {
  const [feedbackType, setFeedbackType] = useState("POSITIVE");
  const [feedbackText, setFeedbackText] = useState("");
  const { isSavingFeedback } = useContext(FeedbackContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onSubmit(feedbackType, feedbackText, () => {
        onClose();
    })
  };

  const verticalClass = verticalPosition === "above" ? "bottom-full mb-2" : "top-full mt-2";
  const horizontalClass = horizontalPosition === "left" ? "left-0" : "right-0";

  return (
    <div className="relative">
      <div className={`absolute ${verticalClass} ${horizontalClass} w-64 bg-white border border-gray-300 rounded-lg shadow-lg z-10`}>
        {!isSavingFeedback ? (
          <form onSubmit={handleSubmit} className="p-4">
            <label className="block mb-2">
              <span className="text-gray-700">Feedback Type:</span>
              <select
                value={feedbackType}
                onChange={(e) => setFeedbackType(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              >
                <option value="POSITIVE">POSITIVE</option>
                <option value="NEGATIVE">NEGATIVE</option>
                <option value="NEUTRAL">NEUTRAL</option>
              </select>
            </label>

            <label className="block mb-2">
              <span className="text-gray-700">Feedback:</span>
              <Textarea
                value={feedbackText}
                onChange={setFeedbackText}
                placeholder="Enter your feedback"
                limit={500}
                textareaStyles="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              />
            </label>
            <div className="flex justify-end mt-4">
              <CustomButton
                type="submit"
                variant="primary"
                size="sm"
                customClass="mr-2"
              >
                Submit
              </CustomButton>
              <CustomButton
                type="button"
                variant="secondary"
                size="sm"
                onClick={onClose}
              >
                Cancel
              </CustomButton>
            </div>
          </form>
        ) : <p className="p-5">Thank you for your feedback!</p>}
      </div>
    </div>
  );
};

export default FeedbackForm;