import CustomButton from "components/partials/custom-button/custom-button";
import { formatDate } from "helpers/dates-formatter";

const commonStyles = "border-r-2 border-white font-semibold";

export const tableSettings = (setSummary, activeIndex) =>
  [
    {
      id: "summaryType",
      accessorKey: "summaryType",
      header: () => <span>Summary Type</span>,
      cell: (info) => <span className="">{info.renderValue()}</span>,
      headerClassName: `${commonStyles} rounded-tl-md rounded-bl-md cursor-pointer`,
      className: "text-left pl-4 w-[20%]",
      enableSorting: false,
      sortingFn: "alphanumeric",
    },
    {
      id: "summarizedBy",
      accessorKey: "summarizedBy",
      header: () => <span>Summarized By</span>,
      cell: (info) => <span className="">{info.renderValue()}</span>,
      headerClassName: `${commonStyles}  cursor-pointer`,
      className: "text-left pl-4 w-[25%]",
      enableSorting: false,
      sortingFn: "alphanumeric",
    },
    {
      id: "summarizedDate",
      accessorKey: "summaryDate",
      header: () => <span>Summarized Date</span>,
      cell: (info) => formatDate(info.row.original.summaryDate, "MMM D, YYYY h:mm A"),
      headerClassName: `${commonStyles} cursor-context-menu`,
      className: "text-left pl-4 sm:w-[30%] py-4",
      enableSorting: false,
    },
    {
      id: "actions",
      header: () => <span className="font-semibold">View Summary</span>,
      cell: (info) => (
        <div className={`flex justify-center ${info.row.index === activeIndex ? "rounded-md bg-yellow-01" : ""}`}>
          <CustomButton variant="secondary" size="sm" onClick={() => setSummary(info.row.original.transcriptSummary, info.row.index, info.row.original.id)}>
            View Summary
          </CustomButton>
        </div>
      ),
      isCellCentered: true,
      isHeaderCentered: true,
      headerClassName: "rounded-tr-md rounded-br-md",
      className: `py-4`,
      enableSorting: false,
    },
  ].filter((item) => item);
