import React from "react";
import Heading from "components/partials/heading/heading";
import { formatDate } from "helpers/dates-formatter";

const MeetingInfo = ({ meetingInfo }) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex justify-between">
        <Heading variant="h1" customClass="font-bold">
          Meeting Info
        </Heading>
      </div>
      <table className="w-full rounded-lg bg-gray-100 shadow-bs-primary">
        <tbody>
          <tr>
            <th className="border-b px-2 py-1 text-left font-semibold">Partner</th>
            <td className="border-b px-2 py-1 text-gray-700">{meetingInfo.client ?? "N/A"}</td>
          </tr>
          <tr>
            <th className="border-b px-2 py-1 text-left font-semibold">Meeting Name</th>
            <td className="border-b px-2 py-1 text-gray-700">{meetingInfo.meetingName}</td>
          </tr>
          <tr>
            <th className="border-b px-2 py-1 text-left font-semibold">Meeting Source</th>
            <td className="border-b px-2 py-1 text-gray-700">{meetingInfo.meetingSource}</td>
          </tr>
          <tr>
            <th className="border-b px-2 py-1 text-left font-semibold">Meeting Source Detail</th>
            <td className="border-b px-2 py-1 text-gray-700">{meetingInfo.meetingSourceDetail}</td>
          </tr>
          <tr>
            <th className="border-b px-2 py-1 text-left font-semibold">Meeting Date</th>
            <td className="border-b px-2 py-1 text-gray-700">{formatDate(meetingInfo.meetingDate, "MMM D, YYYY h:mm A")}</td>
          </tr>
          <tr>
            <th className="border-b px-2 py-1 text-left font-semibold">Meeting Synced By</th>
            <td className="border-b px-2 py-1 text-gray-700">{meetingInfo.meetingSyncedBy}</td>
          </tr>
          {meetingInfo.meetingSource === "GONG" && (
            <tr>
              <th className="px-2 py-1 text-left font-semibold">Gong Call</th>
              <td className="px-2 py-1 text-gray-700">
                <a
                  href={`https://us-8416.app.gong.io/call?id=${meetingInfo.meetingSourceDetail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  Watch Gong Call
                </a>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MeetingInfo;
